import { graphql, Link, useStaticQuery } from 'gatsby'
import React, { useState } from 'react'
/** @jsx jsx */
import { css, jsx, useTheme } from '@emotion/react'
import { useSiteInfoQuery } from '../../queries/useSiteInfoQuery'
import { MarkdownProcessor } from '../Markdown/MarkdownProcessor'
import { useSpring, animated } from 'react-spring'
import useMeasure from '../../customHooks/useMeasure'
import ArrowIcon from './arrowicon'

const CategoryAccordion = ( { categoryTitle, categoryText } ) => {
    const [ isExpanded, setisExpanded ] = useState( false )
    const [ ref, measure ] = useMeasure()

    const { colorHighlight } = useSiteInfoQuery()
    const theme = useTheme()

    const { height } = useSpring( {
        from: { height: 0 },
        to: { height: isExpanded ? measure.height : 0 },
    } )
    const { opacity, transform } = useSpring( {
        from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
        to: { opacity: isExpanded ? 1 : 0, transform: `translate3d(${ isExpanded ? 0 : 20 }px,0,0)` },
    } )

    const stylez = css`
    margin-bottom: 4rem;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    ${ theme.breakpoints.mobile } {
      margin-bottom: 2rem;
    }

    .category_title {
      position: relative;
      font-size: 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      min-width: 215px;
      user-select: none;
      cursor: pointer;
      ${ theme.breakpoints.mobile } {
      font-size: 1.3rem;
    }
      p {
        transition: 0.2s color ease-in-out;
      }
      :hover p {
        color: ${ colorHighlight } !important;
      }
      span {
        position: absolute;
        top: 5px;
        right: -1rem;
        color: ${ colorHighlight };
        font-weight: 600;
        transform: rotate(${ isExpanded ? 90 : 0 }deg);
        transition: transform 0.3s ease-in-out;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        svg {
          height: 1.3rem;
          width: 1.3rem;
        }
      }
    }
    .category_text {
      font-size: 1.2rem;
      margin-top: 2.5rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      overflow: hidden;
      flex: 1;
        ${ theme.breakpoints.mobile } {
      flex: auto;
      margin-top: 1rem;
      font-size: 1.2rem;
    }
      .bold {
        font-weight: 600;
        color: ${ colorHighlight };
      }

    }
  `

    return (
        <div className='category_wrapper' css={ stylez }>
            <div
              className='category_title'
              onClick={ () => setisExpanded( !isExpanded ) }
            >
                <p>{categoryTitle}</p>
                <span>
                    <ArrowIcon
                      color='#d72483'
                    />
                </span>
            </div>

            <animated.div
              className='category_text'
              style={ { height } }
            >
                <animated.div
                  style={ { opacity, transform } }
                  ref={ ref }
                >
                    <MarkdownProcessor markdownElement={ categoryText } />
                </animated.div>
            </animated.div>

        </div>
    )
}

export default CategoryAccordion
