import React from 'react'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Root, MarkdownParagraph, MarkdownLink, MarkdownList, MarkdownListItem } from './MarkdownStyles'

const Bold = ( { children } ) => <span className='bold'>{children}</span>

const options = {
    renderMark: {
        [ MARKS.BOLD ]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
        [ BLOCKS.DOCUMENT ]: ( node, children ) => <Root>{children}</Root>,
        [ BLOCKS.PARAGRAPH ]: ( node, children ) => <MarkdownParagraph node={ node }>{children}</MarkdownParagraph>,
        [ BLOCKS.UL_LIST ]: ( node, children ) => <MarkdownList>{children}</MarkdownList>,
        [ BLOCKS.LIST_ITEM ]: ( node, children ) => <MarkdownListItem>{children}</MarkdownListItem>,
        [ INLINES.HYPERLINK ]: ( node, children ) => <MarkdownLink node={ node }>{children}</MarkdownLink>,
        [INLINES.ASSET_HYPERLINK]: (node, children) => {
            const locale = "en-US";
            const { nodeType, data } = node;
            const url = data.target.fields.file[locale].url;
            switch (nodeType) {
              case "asset-hyperlink":
                return (
                  <a href={url} target='_blank' rel='noreferrer' className='asset_hyperlink'>
                    {children}
                  </a>
                );
              default:
                console.warn(nodeType + " not set");
            }
          },
        [BLOCKS.EMBEDDED_ASSET]: node => {
            return (
              <>
                <h2>Embedded Asset</h2>
                <pre>
                  <code>{JSON.stringify(node, null, 2)}</code>
                </pre>
              </>
            )
          },

    },
}

export const MarkdownProcessor = ( { markdownElement } ) => {
    return documentToReactComponents( markdownElement, options )
}
