import { useRef, useState, useEffect, useMemo } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

export default function useMeasure() {
  const [measure, setMeasure] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0
  });
  const [element, ref] = useState(null);

  const resizeObserver = useMemo(
    () =>
      new ResizeObserver((entries) => {
        if (entries[0]) {
          const { x, y, width, height } = entries[0].contentRect;
          setMeasure({ x, y, width, height });
        }
      }),
    []
  );

  useEffect(() => {
    if (!element) return;
    resizeObserver.observe(element);
    return () => resizeObserver.disconnect();
  }, [element, resizeObserver]);

  return [ref, measure];
}