import React, { useState, useRef, useEffect } from 'react'
import { graphql } from 'gatsby'
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { useTransition, animated, config } from 'react-spring'
import SiteMetadata from '../components/SiteMetadata'
import Layout from '../layouts/Layout'
import { theme } from '../styles/theme'
import { useSiteInfoQuery } from '../queries/useSiteInfoQuery'
import useDocumentScrollThrottled from '../customHooks/useDocumentScrollThrottled'
import CategoryAccordion from '../components/CategoryAccordion/CategoryAccordion'
import { MarkdownProcessor } from '../components/Markdown/MarkdownProcessor'

export default ( props ) => {
    const [ isScrolledEnough, setisScrolledEnough ] = useState( false )
    const [ fadeOutAfterScroll, setfadeOutAfterScroll ] = useState( false )
    const [ isAboveFold, setisAboveFold ] = useState( true )
    const [ fadeOutMobile, setfadeOutMobile ] = useState( false )
    const show = true
    const transitions = useTransition( show, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        delay: 5000,
        config: config.molasses,
    } )

    const aboutRef = useRef( null )

    const {
        siteDescription,
        siteTitle,
        colorText,
        colorHighlight,
    } = useSiteInfoQuery()

    const {
        slogan,
        translationTitle,
        translation,
        adaptationTitle,
        adaptation,
        editingTitle,
        editing,
        aboutMeTitle,
        aboutMe,
        contactTitle,
        contactRightColumn,
        scrollText,
        buttonText,
    } = props.data.item

    const MINIMUM_SCROLL = 40
    const TIMEOUT_DELAY = 100
    const MOBILE_TIMEOUT_DELAY = 2500

    const isInViewport = ( offset = 0 ) => {
        if ( !aboutRef ) return false
        const top = aboutRef.current.getBoundingClientRect().top
        // return (top + offset) >= 0 && (top - offset) <= window.innerHeight;
        return ( top - offset ) <= window.innerHeight
    }

    useEffect( () => {
        setisAboveFold( isInViewport() )
        return () => {
            setisAboveFold( false )
        }
    }, [] )

    useEffect( () => {
        setTimeout( () => {
            setfadeOutMobile( true )
        }, MOBILE_TIMEOUT_DELAY )
    }, [] )

    useDocumentScrollThrottled( ( callbackData ) => {
        const { previousScrollTop, currentScrollTop } = callbackData
        const isScrolledDown = previousScrollTop < currentScrollTop
        const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL

        isScrolledDown && isMinimumScrolled && setisAboveFold( isInViewport() )

        // setisScrolledEnough( isScrolledDown && isMinimumScrolled )

        setTimeout( () => {
            setisScrolledEnough( isScrolledDown && isMinimumScrolled )
        }, TIMEOUT_DELAY )
    } )

    const stylez = css`
    .text_animation {
      position: relative;
    }
    .text_wrapper {
      width: 65%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      ${ theme.breakpoints.mobile } {
      width: 100%;
    }
    }

    .main_content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      /* opacity: ${ isScrolledEnough && isAboveFold ? 0 : 1 }; */
      /* transition: opacity 0.6s ease-out; */
    }
    .CTA {
      background-color: white;
      color: ${ colorHighlight };
      border: 3px solid ${ colorHighlight };
      border-radius: 5px;
      padding: 0.4rem 1.5rem;
      font-size: 1.2rem;
      transition: background-color 0.2s ease-in-out;
      transition: color 0.2s ease-in-out;
      cursor: pointer;
      /* opacity: ${ isScrolledEnough && isAboveFold ? 0 : 1 }; */
      /* transition: opacity 0.6s ease-out; */
      margin-top: 0;
      /* margin-bottom: 2rem; */
      margin-bottom: 5rem;
      user-select: none;
      text-align: center;
      font-weight: 400;
      ${ theme.breakpoints.mobile } {
        margin-bottom: 3rem;
        font-size: 1.3rem;
        /* margin-top: 1rem; */
        }
      :hover {
        background-color: ${ colorHighlight };
        color: white;
      }
    }
    .scroll_more_wrapper {
      /* transform: rotate(270deg);
      transform-origin: right bottom; */
      height: 0;
      width: 90%;
      margin: 0 auto;
      position: fixed;
      bottom: 30%;
      right:0;
      left: 0;
      display: none;
      justify-content: flex-end;
      align-items: center;
      ${ theme.breakpoints.mobile } {
        bottom: 47%;
          width: 97%;
        }

    .scroll_more {
      position: relative;
      transform: rotate(270deg);
      transform-origin: right bottom;
      /* left: 0; */
      /* width: 90%; */
      /* margin: 0 auto; */
      /* width: 100%; */
      /* margin: 4rem auto; */
      /* margin-top: 8rem; */
      /* text-align: right; */
      user-select: none;
      opacity: ${ isScrolledEnough ? 0 : 1 };
      /* opacity: 1; */
      transition: opacity 0.2s ease-in-out;
      font-weight: 100;

      ${ theme.breakpoints.mobile } {
        /* margin: 2rem auto 4rem auto; */
        /* bottom: 1rem; */
        opacity: ${ fadeOutMobile ? 0 : 1 };
        }
        p {
          /* opacity: 0; */
        }
      span {
        font-size: 1.5rem;
        position: absolute;
        bottom: -0.4rem;
        left: -1.5rem;
        transform: rotate(90deg);
        /* right: 0; */
        color: ${ colorHighlight };
      ${ theme.breakpoints.mobile } {
        /* margin: 2rem auto 4rem auto; */
        font-size: 1.2rem;
        left: -1.2rem;
        bottom: -0.2rem;
        opacity: ${ isScrolledEnough ? 0 : 1 };
        }
      }
    }
    }
    .about,
    .contact,
    .cta_wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      width: 100%;
      p {
        /* margin-bottom: 1rem; */
      }

      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 215px;
        user-select: none;
        cursor: pointer;
        font-size: 1.5rem;
        margin-bottom: 2rem;
        ${ theme.breakpoints.mobile } {
          font-size: 1.3rem;
        }
      }
      .text {
        font-size: 1.2rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex: 1;
        min-width: 430px;
        ${ theme.breakpoints.mobile } {
          font-size: 1.2rem;
          min-width: 230px;
        }
        .bold {
          font-weight: 600;
        }
      }
    }
    .about {
      ${ theme.breakpoints.mobile } {
        /* margin-top: 6rem; */
        }

      a {
          color: ${ colorHighlight };
          user-select: none;
          font-weight: 600;
          :hover {
            text-decoration: underline;
          }
          :visited {
            color: ${ colorHighlight };
          }
        }
    }
    .cta_wrapper .title {
      ${ theme.breakpoints.mobile } {
      margin: 0;
      display: none;

        }
    }
    .cta_wrapper .text {
      justify-content: center !important;
    }
    .contact {
      margin-top: 1rem;
      .title {
        color: white;
      }
      .bold {
        color: white;
      }
      a {
          color: white;
          font-weight: 600;

          :visited {
            color: white;
          }
        }
        .text {
          p {
            color: white;
          }
          ${ theme.breakpoints.mobile } {
            flex-wrap: wrap-reverse;
        }
        }
        .contact_left {
          margin-right: 2rem;
          min-width: 215px;
          ${ theme.breakpoints.mobile } {
          min-width: auto;
        }
        }
    }

    footer {
      background-color: ${ colorHighlight };
      width: 100%;
      .footer_wrapper {
        width: 90%;
        margin: 0 auto;
        padding: 3rem 0 12rem 0;
      }
      .text_wrapper {
        flex-direction: row;
      }
    }

    ${ theme.breakpoints.mobile } {
      width: 100%;
    }
    ${ theme.breakpoints.tablet } {
      width: 100%;
    }
    ${ theme.breakpoints.largeDesktop } {
      width: 100%;
    }
  `
    return (

        <div className='container' css={ stylez }>
            <Layout slogan={ slogan }>
                <SiteMetadata title={ siteTitle } description={ siteDescription } />

                {
                    transitions(
                        ( styles, item ) => item && <animated.div style={ styles } className='text_animation'>

                            <div className='text_wrapper'>
                                <section className='main_content'>
                                    <CategoryAccordion
                                      categoryTitle={ translationTitle }
                                      categoryText={ translation.json }
                                    />
                                    <CategoryAccordion
                                      categoryTitle={ adaptationTitle }
                                      categoryText={ adaptation.json }
                                    />
                                    <CategoryAccordion
                                      categoryTitle={ editingTitle }
                                      categoryText={ editing.json }
                                    />
                                </section>

                                <section className='cta_wrapper'>
                                    <div className='title'>&nbsp;</div>
                                    <div className='text'>
                                        <a
                                          className='CTA'
                                          href='mailto:info@jocelynpolen.com'
                                        >
                                            {buttonText}
                                        </a>
                                    </div>
                                </section>

                            </div>

                            <div className='scroll_more_wrapper'>
                                <div className='scroll_more'>
                                    <p>
                                        {scrollText}
                                    </p>
                                    <span>↓</span>
                                </div>
                            </div>

                            <div className='text_wrapper' ref={ aboutRef }>

                                <section className='about'>
                                    <div className='title'>{aboutMeTitle}</div>
                                    <div className='text'>
                                        <MarkdownProcessor markdownElement={ aboutMe.json } />
                                    </div>
                                </section>
                            </div>
                                                    </animated.div>
                    )
                }
            </Layout>

            <footer>
                <div className='footer_wrapper'>
                    <div className='text_wrapper'>
                        <section className='contact'>
                            <div className='title'>{contactTitle}</div>
                            <div className='text'>
                                <div className='contact_right'>
                                    <MarkdownProcessor
                                      markdownElement={ contactRightColumn.json }
                                    />
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </footer>

        </div>
    )
}

export const query = graphql`
  query ProjectItemQUery($language: String!) {
    item: contentfulHomepageTextContent(language: { eq: $language }) {
      slogan
      translationTitle
      translation {
        json
      }
      adaptationTitle
      adaptation {
        json
      }
      editingTitle
      editing {
        json
      }
      aboutMeTitle
      aboutMe {
        json
      }
      contactTitle
      contactRightColumn {
        json
      }
      scrollText
      buttonText
    }
  }
`
