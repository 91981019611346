import * as React from 'react'


const ArrowIcon = ( { width, height, color = 'white', deg = 0, className } ) => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.205 12.318">
  <path fill="#d72483" d="M7.205 6.159a.983.983 0 00-.342-.759L1.62.273A.877.877 0 00.957 0 .954.954 0 000 .95a.97.97 0 00.3.69l4.648 4.512L.3 10.678a.947.947 0 00-.3.69.954.954 0 00.957.95.887.887 0 00.663-.273l5.243-5.127a.993.993 0 00.342-.759z" data-name="􀆊"/>
</svg>
    )
}

export default ArrowIcon
